var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"main-wrapper"},[_c('div',{staticClass:"axil-checkout-area axil-section-gap"},[_c('div',{staticClass:"container"},[_c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.sendOrder($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"axil-checkout-billing"},[_c('h4',{staticClass:"title mb--40"},[_vm._v(" "+_vm._s(_vm.$t("Szállítási adatok"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("Név"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],attrs:{"type":"text","id":"first-name","placeholder":"Adam Smith"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("E-mail"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],attrs:{"type":"email","id":"email","placeholder":"adam.smith@gmail.com"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"top":"-22px !important"}},[_vm._v(_vm._s(_vm.$t("Telefonszám"))+" "),_c('span',[_vm._v("*")])]),_c('VuePhoneNumberInput',{attrs:{"no-example":true,"default-country-code":"HU","preferred-countries":[
                                                'HU',
                                                'DE',
                                                'SK',
                                                'FR'
                                            ]},on:{"update":_vm.setPhoneUpdate},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)])]),_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"z-index":"unset !important"}},[_vm._v(_vm._s(_vm.$t("Szállítási mód"))+" "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.shipment_method),expression:"form.shipment_method"}],attrs:{"id":"Region"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "shipment_method", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.shipments),function(item,index){return _c('option',{key:'ship-' + index,domProps:{"value":index}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),(
                                    _vm.form.shipment_method ===
                                        'Foxpost csomagpont'
                                )?_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"z-index":"unset !important"}},[_vm._v(_vm._s(_vm.$t("Foxpost csomagpont"))+" "),_c('span',[_vm._v("*")])]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.foxpost),expression:"form.foxpost"}],attrs:{"id":"foxpost"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "foxpost", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.foxpost),function(item,index){return _c('option',{key:'ship-' + index,domProps:{"value":item.value}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)]):_vm._e(),(
                                    _vm.form.shipment_method ===
                                        'Foxpost házhozszállítás' ||
                                        _vm.form.shipment_method ===
                                            'MPL házhozszállítás'
                                )?[_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"z-index":"unset !important"}},[_vm._v(_vm._s(_vm.$t("Irányítószám"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.zip),expression:"form.zip"}],attrs:{"type":"text","id":"zip"},domProps:{"value":(_vm.form.zip)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "zip", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"z-index":"unset !important"}},[_vm._v(_vm._s(_vm.$t("Város"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.city),expression:"form.city"}],attrs:{"type":"text","id":"town"},domProps:{"value":(_vm.form.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "city", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"z-index":"unset !important"}},[_vm._v(_vm._s(_vm.$t("Utca / házszám"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.address),expression:"form.address"}],staticClass:"mb--15",attrs:{"type":"text","id":"address1","placeholder":_vm.$t('Utca / házszám')},domProps:{"value":(_vm.form.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "address", $event.target.value)}}})])]:_vm._e(),_c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"z-index":"unset !important"}},[_vm._v(_vm._s(_vm.$t("Megjegyzés")))]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.note),expression:"form.note"}],attrs:{"id":"notes","rows":"2","placeholder":_vm.$t(
                                            'Megjegyzés a rendeléssel kapcsolatban'
                                        )},domProps:{"value":(_vm.form.note)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "note", $event.target.value)}}})]),_c('div',{staticClass:"form-group input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.aszf),expression:"form.aszf"}],attrs:{"type":"checkbox","id":"aszf","name":"terms-privacy"},domProps:{"checked":Array.isArray(_vm.form.aszf)?_vm._i(_vm.form.aszf,null)>-1:(_vm.form.aszf)},on:{"change":function($event){var $$a=_vm.form.aszf,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "aszf", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "aszf", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "aszf", $$c)}}}}),_c('label',{attrs:{"for":"aszf"}},[_vm._v(_vm._s(_vm.$t( "Rendelésemmel elfogadom az adatvédelmi nyilatkozatot és az általános szerződési feltételeket." )))])])],2)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"axil-order-summery order-checkout-summery"},[_c('h5',{staticClass:"title mb--20"},[_vm._v(" "+_vm._s(_vm.$t("Termékek"))+" ")]),_c('div',{staticClass:"summery-table-wrap"},[_c('table',{staticClass:"table summery-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("Termék")))]),_c('th',[_vm._v(_vm._s(_vm.$t("Részösszeg")))])])]),_c('tbody',[_vm._l((_vm.cart),function(item,index){return _c('tr',{key:'cart-item-' + index,staticClass:"order-product"},[_c('td',{staticStyle:{"text-wrap":"balance"}},[_vm._v(" "+_vm._s(item.name)+" "),(item.is_foil)?_c('i',{staticClass:"fa fa-star"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"quantity"},[_vm._v("x"+_vm._s(item.quantity))])]),_c('td',[_vm._v(" "+_vm._s(item.price_formatted)+" ")])])}),_c('tr',{staticClass:"order-subtotal"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t("Szállítási költség"))+" ")]),(
                                                    _vm.form.shipment_method ===
                                                        'Foxpost házhozszállítás' &&
                                                        _vm.cartSubTotal
                                                )?_c('td',[_vm._v(" "+_vm._s(_vm.foxpost_home_formatted)+" Ft ")]):(
                                                    _vm.form.shipment_method ===
                                                        'Foxpost csomagpont' &&
                                                        _vm.cartSubTotal
                                                )?_c('td',[_vm._v(" "+_vm._s(_vm.foxpost_packet_formatted)+" Ft ")]):(
                                                    _vm.form.shipment_method ===
                                                        'MPL házhozszállítás' &&
                                                        _vm.cartSubTotal
                                                )?_c('td',[_vm._v(" "+_vm._s(_vm.mpl_home_formatted)+" Ft ")]):_c('td',[_vm._v(" 0 Ft ")])]),(_vm.form.credit > 0)?_c('tr',{staticClass:"order-subtotal"},[_c('td',[_vm._v(" "+_vm._s(_vm.$t( "Felhasználható kredit" ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.form.credit)+" Ft ")])]):_vm._e(),_c('tr',{staticClass:"order-subtotal"},[_c('td',[_vm._v(_vm._s(_vm.$t("Részösszeg")))]),_c('td',[_vm._v(" "+_vm._s(_vm.totalPaymentWoS)+" Ft ")])]),_c('tr',{staticClass:"order-shipping"},[_c('td',{attrs:{"colspan":"2"}},[_c('div',{staticClass:"shipping-amount"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("Fizetési mód")))])]),_vm._l((_vm.payOptions),function(item,index){return _c('div',{key:'pay-opt-' + index,staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                                            _vm.form.payment_method
                                                        ),expression:"\n                                                            form.payment_method\n                                                        "}],attrs:{"type":"radio","id":'radio' + index,"name":"payment_method"},domProps:{"value":item,"checked":item ===
                                                                _vm.form.payment_method,"checked":_vm._q(
                                                            _vm.form.payment_method
                                                        ,item)},on:{"change":function($event){return _vm.$set(_vm.form, "payment_method", item)}}}),_c('label',{attrs:{"for":'radio' + index}},[_vm._v(_vm._s(item))])])})],2)]),_c('tr',{staticClass:"order-total"},[_c('td',[_vm._v(_vm._s(_vm.$t("Összesen")))]),_c('td',{staticClass:"order-total-amount"},[_vm._v(" "+_vm._s(_vm.totalPayment)+" Ft ")])])],2)])]),(_vm.totalPaymentWoS >= 500)?_c('button',{staticClass:"axil-btn btn-bg-primary checkout-btn",attrs:{"type":"submit","disabled":_vm.orderSent}},[_vm._v(" "+_vm._s(_vm.$t("Megrendelés"))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.$t("Minimum rendelés: 500 Ft"))+" ")])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }